import { useState } from "react";
import emailjs from "@emailjs/browser";

export const useForm = (initialForm, validateForm) => {
  const [form, setForm] = useState(initialForm);
  const [erros, setErros] = useState({});
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  //console.log(form)
  //La validacion de errores puede ser en el blur o el submit
  //como se prefiera
  const handleBlur = (e) => {
    handleChange(e);
    setErros(validateForm(form));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErros(validateForm(form));

    if (Object.keys(erros).length === 0) {
      setLoading(true);
      setResponse(true);
      setForm(form);
      setTimeout(() => {
        return setResponse(false), setLoading(false);
      }, 7000);

      console.log(form);
      emailjs
        .sendForm(
          "service_7i4qlhe",
          "template_s79sx75",
          e.target,
          "T-I_SQH7Z2ou3m3vn"
        )
        .then((response) => console.log(response))
        .catch((error) => console.log(error));
    } else {
      return;
    }
  };

  return {
    form,
    erros,
    loading,
    response,
    handleSubmit,
    handleChange,
    handleBlur,
  };
};
